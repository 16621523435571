<template>
  <div class="d-flex flex-column flex-root" v-if="isAuthenticated">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <!-- <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader> -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside
        v-if="asideEnabled"
        @HelpButtonValue="updateHelpValue"
        @RefreshButtonValue="updateRefreshValue"
        :key="componentKey"
        :method="forceRerender"
        :propName="passedPropName"
      ></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid"
          :class="pageClass()"
          style="background: #f8f8f9"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader
            v-if="subheaderDisplay"
            v-bind:breadcrumbs="breadcrumbs"
            v-bind:title="pageTitle"
          />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <transition name="fade-in-up">
                <router-view
                  :helpValue="helpValue"
                  :refreshValue="refreshValue"
                  @notif-refresh="notifRefresh"
                />
              </transition>
            </div>
          </div>
        </div>

        <KTFooter></KTFooter>
      </div>
    </div>
    <KTStickyToolbar :showToolbar="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>
    <!-- <b-modal
      id="logout-warning-modal"
      size="lg"
      title="Inactivity Warning"
      v-model="LogoutWarningModal"
      centered
      hide-footer
    >
      <p class="my-5">
        You have not performed any action on RMD within the past 10mins and we
        have locked this session for data security. Please enter your password
        or log out to continue
      </p>
      <div class="form-group row">
        <label for="inputPassword" class="col-sm-2 col-form-label"
          >Password</label
        >
        <div class="col-sm-10">
          <div class="input-group">
            <input
              class="form-control border-right-0 "
              id="inputPassword"
              placeholder="Password"
              v-model="password"
              @focus="isFocused = true"
              :type="showPassword ? 'text' : 'password'"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary border-left-0"
                type="button"
                @click="toggleShowPassword"
              >
                <i
                  class="fa"
                  :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-5">
        <b-button variant="danger" @click="logoutUser">Logout</b-button>
        <b-button variant="primary" @click="continueLogin">Continue</b-button>
      </div>
    </b-modal> -->
    <div v-if="LogoutWarningModal" class="modal">
      <div class="modal-content">
        <!-- <span class="close" @click="LogoutWarningModal = false">&times;</span> -->
        <h2 class="modal-title">Inactivity Warning</h2>
        <p class="my-5">
          You have not performed any action on RMD within the past 10 minutes
          and we have locked this session for data security. Please enter your
          password or log out to continue.
        </p>
        <div class="form-group row">
          <label for="inputPassword" class="col-sm-2 col-form-label">
            Password
          </label>
          <div class="col-sm-10">
            <div class="input-group">
              <input
                class="form-control"
                id="inputPassword"
                placeholder="Password"
                v-model="password"
                @focus="isFocused = true"
                :type="showPassword ? 'text' : 'password'"
              />

              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary border-left-0"
                  type="button"
                  @click="toggleShowPassword"
                >
                  <i
                    class="fa"
                    :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                  ></i>
                </button>
              </div>
            </div>
            <div v-if="passwordError" class="text-danger mt-2">
              {{ passwordError }}
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-between mt-5">
          <button class="btn btn-danger" @click="logoutUser">Logout</button>
          <button class="btn btn-primary" @click="continueLogin">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  LOGOUT,
  LOGIN,
  // PURGE_AUTH
} from "@/core/services/store/auth.module";
import JwtService from "@/core/services/jwt.service";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile.vue";
import KTFooter from "@/view/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import Swal from "sweetalert2";
// import Loader from "@/view/content/Loader.vue";
import axios from "axios";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    // Loader,
  },
  data() {
    return {
      helpValue: new Date(),
      refreshValue: new Date(),
      passedPropName: new Date(),
      inactivityTimer: null,
      inactivityDuration: 600000, // 10 minutes in milliseconds
      LogoutWarningModal: false,

      showPassword: false,
      password: "",
      passwordError: "",
      isFocused: false,
    };
  },
  beforeDestroy() {
    // window.removeEventListener("beforeunload", this.handleBeforeUnload);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },
  created() {
    // window.addEventListener("beforeunload", this.handleBeforeUnload);
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // console.log("Session timed out");
          // Redirect the user to the login page
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );
    this.startInactivityTimer();

    // Add event listeners to detect user activity
    window.addEventListener("mousemove", this.handleUserActivity);
    window.addEventListener("keydown", this.handleUserActivity);
    window.addEventListener("mousedown", this.handleUserActivity);
    window.addEventListener("touchstart", this.handleUserActivity);
  },
  destroy() {
    clearTimeout(this.inactivityTimer);
    window.removeEventListener("mousemove", this.handleUserActivity);
    window.removeEventListener("keydown", this.handleUserActivity);
    window.removeEventListener("mousedown", this.handleUserActivity);
    window.removeEventListener("touchstart", this.handleUserActivity);
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.$store.getters.isAuthenticated) {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));

      // this.$router.push({ name: "login" });
    } else {
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }, 2000);
    }

    //check net::ERR_CONNECTION_TIMED_OUT
    window.onerror = (message) => {
      if (message === "net::ERR_CONNECTION_TIMED_OUT") {
        this.$store
          .dispatch(LOGOUT)
          .then(() => this.$router.push({ name: "login" }));
      }
    };
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    handleBeforeUnload(event) {
      // Call the JWT token destruction method
      JwtService.destroyToken();

      // Display a custom alert message
      const confirmationMessage =
        "Are you sure you want to leave? Your changes may not be saved.";
      event.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // For some older browsers
    },
    // handleBeforeUnload(event) {
    //   // For modern browsers
    //   event.preventDefault();
    //   // Chrome requires returnValue to be set
    //   event.returnValue = "Are you sure you want to reload?";
    // },
    startInactivityTimer() {
      this.inactivityTimer = setTimeout(() => {
        this.LogoutWarningModal = true;
        this.password = "";
      }, this.inactivityDuration);
      if (this.LogoutWarningModal) {
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        // axios.interceptors.response.use(
        //   (response) => response,
        //   (error) => {
        //     if (error.response.status === 401) {
        //       // console.log("Session timed out");
        //       // // Redirect the user to the login page
        // JwtService.destroyToken();
        //       window.location.href = "/login";
        //     }
        //     return Promise.reject(error);
        //   }
        // );
      }
    },

    resetInactivityTimer() {
      clearTimeout(this.inactivityTimer);

      this.startInactivityTimer();
    },
    logoutUser() {
      this.$router.push("/login");
    },

    continueLogin() {
      const email = this.currentUser.userData.email;
      const password = this.password;
      if (!password) {
        this.passwordError = "Password cannot be empty";
        return; // Exit function if password is empty
      }
      this.$store
        .dispatch(LOGIN, { email, password })
        // go to which page after successfully login
        .then(() => {
          this.LogoutWarningModal = false;
        })
        .catch(() => {
          Swal.close();

          this.$bvToast.toast("Invalid Password", {
            title: "Login",
            variant: "danger",
            solid: true,
          });
          // }
          // this.$store.dispatch('LOGOUT').then(() => this.$router.push({ name: 'login' }));
        });
    },
    handleUserActivity() {
      this.resetInactivityTimer();
    },

    notifRefresh(value) {
      this.passedPropName = value;
      // this.refreshValue = value;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    updateHelpValue(val) {
      this.helpValue = val;
    },
    updateRefreshValue(val) {
      this.refreshValue = val;
    },
    pageClass() {
      return this.$route.name;
    },
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "rightAsideConfig",
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    // loaderLogo() {
    //   return process.env.BASE_URL + this.layoutConfig("loader.logo");
    // },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      const rightSide = this.rightAsideConfig;
      return rightSide.display;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Poppins");

.page {
  font-family: "Poppins", sans-serif;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  max-width: 600px;
  padding: 20px;
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}
</style>
